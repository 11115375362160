import { Controller } from "stimulus"
import Chart from 'chart.js'

export default class extends Controller {
  static targets = [ "canvas" ]
  static values = {
    chartType: String,
    chartOptions: Object,
    stacked: Boolean
  }

  connect() {
    this.canvasTarget.height = 170
    this.canvasTarget.width = 400

    new Chart(this.canvasTarget, {
      type: this.chartTypeValue, 
      responsive: false,
      maintainAspectRatio: false,
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: this.stackedValue
          }],
          yAxes: [{
            stacked: this.stackedValue
          }]
        }
      },
      data: this.chartOptionsValue
    })
  }
}