import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.autoHideFlash(this.element)
  }

  autoHideFlash(element) {
    element.style.transition = "opacity 2s"

    new Promise((resolve, reject)=> {
      setTimeout(resolve, 3000)
    }).then(()=> {
      element.classList.add('opacity-0')
      setTimeout(() => {
        element.classList.add('hidden')
      }, 2000)
    })
  }

  close() {
    this.element.classList.add('hidden')
  }
}
