import { Controller } from "stimulus"
import { Guid } from "../helpers/guid"
import consumer from "../channels/consumer"
import { FlashHelper } from "../helpers/flash_helper"

export default class extends Controller {
  static targets = ["query", "table", "filters", "filterTemplate", "connection"]

  connect() {
    this.initiateTabulator()
    this.subToChannel()
  }

  subToChannel() {
    const uuid = Guid.generate()
    let self = this

    this.subscription = consumer.subscriptions.create(
      { channel: "DatasetChannel", uuid: uuid },
      {
        received(response) {
          if (response.format == "html") {
            TableRegister.getInstance("resultsTable").setData(response.content.data)
          }
          else {
            FlashHelper.render(response.content, "alert")
          }

          document.getElementsByClassName("tabulator-loader")[0].classList.add("hidden")
        },
        run(query, connectionId) {
          this.perform("run", { connection_id: connectionId, query: query })
        }
      }
    )
  }

  initiateTabulator() {
    TableRegister.register("resultsTable", this.tableTarget, {
      layout:"fitColumns",
      autoColumns:true,
      height:"300px",
      pagination:"local",
    })
    
    let loader = document.getElementById("loader").innerHTML
    this.tableTarget.insertAdjacentHTML("beforeend", loader)
  }

  query(e) {
    e.preventDefault()
    if (this.queryTarget.value != "") {
      document.getElementsByClassName("tabulator-loader")[0].classList.remove("hidden")
      this.subscription.run(this.queryTarget.value, this.connectionTarget.value)
    }
  }

  newFilter(e) {
    e.preventDefault()

    this.filtersTarget.insertAdjacentHTML("beforeend", this.filterTemplateTarget.innerHTML)
  }

  removeFilter(e) {
    e.preventDefault()

    e.currentTarget.parentNode.parentNode.removeChild(e.currentTarget.parentNode)
  }
}
