import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "comparison",
    "columns",
    "value",
    "filterContainer",
    "inputContainer",
    "modal",
    "dateInput"
  ]
  static values = {
    filters: Array,
    updateUrl: String,
    firstRun: Boolean
  }

  // Helper for adding a period filter to a date type column
  periodInput(e) { 
    switch (e.target.value) {
      case "period":
        this.dateInputTarget.innerHTML = `<input type="number" class="input__field w-full" data-filters-target="value">`
        break;
      case "today":
        this.dateInputTarget.innerHTML = `<input type="hidden" value="0" data-filters-target="value">`
        break;
      case "last_week":
        this.dateInputTarget.innerHTML = `<input type="hidden" value="7" data-filters-target="value">`
        break;
      case "last_month":
        this.dateInputTarget.innerHTML = `<input type="hidden" value="30" data-filters-target="value">`
        break;
      case "last_year":
        this.dateInputTarget.innerHTML = `<input type="hidden" value="365" data-filters-target="value">`
        break;
      default:
        this.dateInputTarget.innerHTML = `<input data-filters-target="value" data-controller="flatpickr" type="text" class="input__field w-full">`
        break;
    }
  }

  remove(e) {
    let newFilters = this.filtersValues
    const column = e.target.parentNode.dataset.column,
      comparison = e.target.parentNode.dataset.comparison,
      value = e.target.parentNode.dataset.value
  
    newFilters = newFilters.filter(obj => obj.column !== column && obj.comparison !== comparison && obj.value !== value )

    if(newFilters.length === 0) {
      this.filterContainerTarget.innerHTML = `<input type="hidden" name="inquiry[filters[]]">`
    } else {
      e.target.parentNode.parentNode.removeChild(e.target.parentNode)
    }

    this.filtersValues = newFilters
  }

  filtersValuesChanged() {
    if (this.hasFiltersValues && !this.firstRunValue) {
      const mainController = this.element.dataset.controller.split(" ")[2],
        subscriber = this.application.getControllerForElementAndIdentifier(this.element, mainController)
      
      document.getElementsByClassName("tabulator-loader")[0].classList.remove("hidden")
      subscriber.subscription.run(this.serializedFilters, "html")

      this.modalTarget.open = false
    }
  }

  get serializedFilters() {
    let serialized = []

    this.filtersValues.forEach(element => {
      for (const [key, value] of Object.entries(element)) {
        serialized.push(`filters[]${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    })

    return serialized.join('&')
  }
  
  applyFilter(e) {
    e.preventDefault()

    let value = "", comparisonLabel = ""
    
    if (this.columnsTarget.selectedOptions[0].dataset.type === "boolean") {
      comparisonLabel = this.comparisonTarget.dataset.label
      value = this.valueTarget.checked
    } else {
      comparisonLabel = this.comparisonTarget.selectedOptions[0].label
      value = this.valueTarget.value
    }

    this.addFilterToPage(value, comparisonLabel)
    this.updateFilterValues(value)

    this.inputContainerTarget.innerHTML = ""
    this.columnsTarget.slim.set()
  }

  updateFilterValues(value) {
    let newFilters = this.filtersValues
    
    newFilters.push({ 
      column: this.columnsTarget.value, 
      comparison: this.comparisonTarget.value,
      value: value
    })

    this.filtersValues = newFilters
  }

  addFilterToPage(value, comparisonLabel) {
    let valueLabel = ` ${value}`

    if(this.comparisonTarget.value === "today" ||
       this.comparisonTarget.value === "last_week" || 
       this.comparisonTarget.value === "last_month" ||
       this.comparisonTarget.value === "last_year") {
        valueLabel = ""
    }

    this.filterContainerTarget.insertAdjacentHTML("beforeend", `
      <span class="px-2 py-1 text-sm rounded-lg text-gray-600 bg-background border border-gray-300" 
      data-column="${this.columnsTarget.selectedOptions[0].label}" 
      data-comparison="${this.comparisonTarget.value}" 
      data-value="${value}">
        ${this.columnsTarget.selectedOptions[0].label} ${comparisonLabel}${valueLabel}
        <span data-action="click->filters#remove" class="text-xl cursor-pointer">x</span>
        <input type="hidden" name="inquiry[filters[][column]]" value="${this.columnsTarget.value}">
        <input type="hidden" name="inquiry[filters[][comparison]]" value="${this.comparisonTarget.value}">
        <input type="hidden" name="inquiry[filters[][value]]" value="${value}">
      </span>
    `)
  }

  changeValueFormat(event) {
    if(event.target.selectedIndex !== -1) {
      const type = event.target.selectedOptions[0].dataset.type
    
      let templateId = ""

      switch (type) {
        case "character varying":
        case "text":
        case "character":
          templateId = "stringTemplate"
          break;
        case "integer":
        case "small integer":
        case "bigint":
        case "numeric":
        case "number":
        case "real":
        case "double precision":
          templateId = "numberTemplate"
          break;
        case "date":
          templateId = "dateTemplate"
          break;
        case "dateTime":
        case "timestamp":
        case "timestamp without time zone":
        case "timestamp with time zone":
        case "time":
        case "time without time zone":
        case "time with time zone":
          templateId = "dateTimeTemplate"
          break;
        case "boolean":
          templateId = "booleanTemplate"
          break;
      }

      const template = document.getElementById(templateId)
      
      this.inputContainerTarget.innerHTML = ""
      this.inputContainerTarget.append(template.content.cloneNode(true))
    }
  }
}