import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["inquiry"]

  unload() {
    this.inquiryTargets.forEach(element => {
      element.removeAttribute("data-loaded")
    })
  }
}