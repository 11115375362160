import { Controller } from "stimulus"
import { Guid } from "../helpers/guid"
import consumer from "../channels/consumer"
  
export default class extends Controller {
  static targets = ["table"]
  static values = {
    inquiryId: Number,
    dashboardInquiryId: Number
  }
  
  connect() {
    if (!this.element.dataset.loaded) {
      this.initiateTabulator()
      this.subToChannel()
      this.executeQuery()
    }
  }
  
  subToChannel() {
    const uuid = Guid.generate()
    let self = this
    
    this.subscription = consumer.subscriptions.create(
      { channel: "InquiryChannel", uuid: uuid },
      {
        received(response) {
          if (response.format == "html") {
            TableRegister.getInstance(self.dashboardInquiryIdValue + "_table").setData(self.getFilteredTableData(response.content.data))
            self.element.setAttribute("data-loaded", "true")
          }
          else {
            console.error(response.content)
          }
            
          self.tableTarget.querySelector(".tabulator-loader").classList.add("hidden")
        },
        run() {
          this.perform("run", { id: self.inquiryIdValue, editable: false, format: "html" })
        }
      }
    )
  }

  getFilteredTableData(data) {
    const columnController = this.application.getControllerForElementAndIdentifier(this.element, "column")
    let columns = columnController.columnsValues

    return data.map(item => {
      let object = {}
      columns.forEach(column => {
        object[column] = item[column]
      })
      return object
    })
  }

  initiateTabulator() {
    TableRegister.register(this.dashboardInquiryIdValue + "_table", this.tableTarget, {
      layout:"fitColumns",
      autoColumns:true,
      height:"300px",
      pagination:"local",
    })
    
    let loader = document.getElementById("loader").innerHTML
    this.tableTarget.insertAdjacentHTML("beforeend", loader)
  }

  executeQuery() {
    const self = this
    
    this.tableTarget.querySelector(".tabulator-loader").classList.remove("hidden")
    setTimeout(() => self.subscription.run(), 2000)
  }
}