import { Controller } from "stimulus"
import "tabulator-tables/dist/css/tabulator.min.css"
import { Guid } from "../helpers/guid"
import consumer from "../channels/consumer"
import { ChartOptions } from "../helpers/chart_options"
import { UrlHelper } from "../helpers/url_helper"
import Chart from 'chart.js'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    "table",
    "chart",
    "label",
    "chartValue",
    "addDashboard",
    "removeDashboard",
    "pdfDownload",
    "xlsxDownload",
    "description",
    "previewContainer",
    "tablePlaceholder",
    "fidlerContainer",
    "chartCreation",
    "filtersSelect",
    "columnSelect"
  ]
  static values = {
    queryData: Array,
    dataset: Object,
    id: Number,
    newRecord: Boolean,
    datasetId: Number,
    editable: Boolean,
    currentAccount: Number
  }

  connect() {
    this.setChartDefaults()
    this.initiateTabulator()
    this.subToChannel()
    if (this.element.dataset.preview) {
      this.executeQuery()
    } else if (!this.newRecordValue) {
      this.loadDataset()
    }
  }

  setChartDefaults() {
    Chart.defaults.scale.gridLines.drawOnChartArea = false
    Chart.scaleService.updateScaleDefaults('linear', {
      ticks: {
        min: 0
      }
    })
    Chart.defaults.scale.ticks.callback = tick => {
      var characterLimit = 5;
      if ( tick.length >= characterLimit) {
        return tick.slice(0, tick.length).substring(0, characterLimit -1).trim() + '...'
      }
      return tick
    }
  }

  loadDataset(e = null) {
    const self = this
    let datasetId = 0

    if (e == null) {
      datasetId = this.datasetIdValue
    } else {
      datasetId = e.target.value
    }

    Rails.ajax({
      type: "GET",
      url: UrlHelper.account_url(`/datasets/${datasetId}`),
      dataType: "json",
      complete: data => {
        self.datasetValue = JSON.parse(data.response)
      }
    })
  }

  datasetValueChanged() {
    if (this.hasDatasetValue) {
      this.initiateWorkspace()
    }
  }

  getFilteredTableData() {
    let data = TableRegister.getInstance("showTable").getData()

    const columnController = this.application.getControllerForElementAndIdentifier(this.element, "column")
    let columns = columnController.columnsValues

    return data.map(item => {
      let object = {}
      columns.forEach(column => {
        object[column] = item[column]
      })
      return object
    })
  }

  initiateWorkspace() {
    this.descriptionTarget.innerHTML = this.datasetValue.description
    this.previewContainerTarget.classList.remove("items-center", "justify-around", "h-56")
    this.previewContainerTarget.classList.remove("content-start")
    this.previewContainerTarget.innerHTML = this.datasetPreview

    this.executeQuery()

    this.tableTarget.classList.remove("hidden")
    this.tablePlaceholderTarget.classList.add("hidden")
    this.fidlerContainerTarget.classList.remove("hidden")

    this.chartCreationTarget.classList.remove("hidden")

    if (this.newRecordValue) {
      this.initializeColumns()
      this.initializeFilters()
    }
  }

  get datasetPreview() {
    let html = ""

    this.datasetValue.columns.forEach(column => {
      html += ` <span style="height: fit-content" class="rounded-lg bg-gray-100 px-2 py-1 block mb-2 text-sm ml-2 text-gray-500 border border-gray-200">
                  ${Object.values(column)[0]}
                </span>
              `
    })

    return html
  }

  setLabel(e) {
    e.preventDefault()

    document.querySelectorAll(".icon-link").forEach(element => {
      element.classList.remove("icon-link--selected")
    })

    this.chartTarget.parentNode.classList.add("hidden")

    e.currentTarget.classList.add("icon-link--selected")
    this.chartValueTarget.value = e.currentTarget.dataset.chartType

    const tableData = this.getFilteredTableData()
    this.labelTarget.innerHTML = `
      <div class="my-5 text-center" data-inquiry-target="label">
        <span class="font-semibold text-6xl">${Object.values(tableData[0])[0]}</span>
        <span class="text-5xl font-light">${Object.keys(tableData[0])[0]}</span>
      </div>
    `

    this.labelTarget.parentNode.classList.remove("hidden")
  }

  setChart(e) {
    for (const key in Chart.instances)
      if (Chart.instances.hasOwnProperty(key))
        Chart.instances[key].destroy()

    e.preventDefault()

    document.querySelectorAll(".icon-link").forEach(element => {
      element.classList.remove("icon-link--selected")
    })

    e.currentTarget.classList.add("icon-link--selected")

    const tableData = this.getFilteredTableData()
    new Chart(this.chartTarget, ChartOptions[e.currentTarget.dataset.chartType](tableData))

    this.chartTarget.parentNode.classList.remove("hidden")
    this.chartTarget.classList.remove("hidden")
    this.labelTarget.parentNode.classList.add("hidden")

    this.chartValueTarget.value = e.currentTarget.dataset.chartType
  }

  removeChart(e) {
    e.preventDefault()

    e.currentTarget.parentNode.classList.add("hidden")
    this.chartValueTarget.value = ""
  }

  initiateTabulator() {
    TableRegister.register("showTable", this.tableTarget, {
      layout: "fitColumns",
      autoColumns: true,
      movableColumns: true, //enable user movable columns
      height: "400px",
      pagination: "local",
    })

    if (this.newRecordValue) {
      this.tableTarget.classList.add("hidden")
    }

    let loader = document.getElementById("loader").innerHTML
    this.tableTarget.insertAdjacentHTML("beforeend", loader)
  }

  queryDataValuesChanged() {
    if (this.hasQueryDataValues) {
      const table = TableRegister.getInstance("showTable"),
        columnController = this.application.getControllerForElementAndIdentifier(this.element, "column")

      table.setData(this.queryDataValues)
      columnController.toggleColumnVisibility()

      if(this.chartValueTarget.value !== "") {
        const tableData = this.getFilteredTableData()
        if (this.chartValueTarget.value === "label") {
          this.labelTarget.innerHTML = `
            <div class="my-5 text-center" data-inquiry-target="label">
              <span class="font-semibold text-6xl">${Object.values(tableData[0])[0]}</span>
              <span class="text-5xl font-light">${Object.keys(tableData[0])[0]}</span>
            </div>
          `
        } else {
          new Chart(this.chartTarget, ChartOptions[this.chartValueTarget.value](tableData))
        }
      }
    }
  }

  subToChannel() {
    const uuid = Guid.generate()
    let self = this

    this.subscription = consumer.subscriptions.create(
      { channel: "InquiryChannel", uuid: uuid },
      {
        received(response) {
          if (response["format"] == "html") {
            self.queryDataValues = response.content.data
            document.getElementsByClassName("tabulator-loader")[0].classList.add("hidden")
          } else if (response["format"] == "error") {
            console.error(response.content)
          } else {
            self.downloadDocument(response)
          }
        },
        run(filters, type) {
          this.perform("run", {
            id: self.idValue,
            filters: filters,
            editable: self.editableValue,
            dataset_id: self.datasetValue.id,
            newRecord: self.newRecordValue,
            format: type
          })
        }
      }
    )
  }

  downloadDocument(data) {
    const binary = data["html"]
    const type = data["format"]

    const linkSource = `data:application/${type};base64,${binary}`;
    let downloadLink = document.createElement("a");
    downloadLink.setAttribute("target", "_blank")

    const fileName = `${data["title"]}.${type}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "_blank"

    this.element.appendChild(downloadLink)
    downloadLink.click()
  }

  /* We are referencing other controllers from this 'main' controller. This was done so controllers like
  column and filters (and others in the future) can have a more focused approach and for this
  controller to be less cluttered. Adding the Stimulus Values and Classes APIs allowed for
  a unified client-side data storage. This means that, with the approach under this comment
  we don't have to worry about data consistency across controllers.*/
  executeQuery() {
    const self = this,
      filtersController = this.application.getControllerForElementAndIdentifier(this.element, "filters")

    document.getElementsByClassName("tabulator-loader")[0].classList.remove("hidden")

    if (this.newRecordValue) {
      this.subscription.run(filtersController.serializedFilters)
    } else if (this.element.dataset.preview) {
      setTimeout(() => self.subscription.run(""), 2000)
    } else {
      setTimeout(() => self.subscription.run(filtersController.serializedFilters), 2000)
    }

    if (filtersController !== null) {
      filtersController.firstRunValue = false
    }
  }

  initializeFilters() {
    const filters = this.datasetValue.filters
    let placeholder = new Option(this.filtersSelectTarget.dataset.placeholder)
    placeholder.setAttribute("data-placeholder", true)

    this.filtersSelectTarget.slim.setData([])
    this.filtersSelectTarget[this.filtersSelectTarget.options.length] = placeholder

    filters.forEach(filter => {
      let option = new Option(filter.translation, filter.name)
      option.setAttribute("data-type", filter.type)

      this.filtersSelectTarget[this.filtersSelectTarget.options.length] = option
    })
  }

  initializeColumns() {
    let options = [],
      columns = []

    const keys = Object.keys(this.datasetValue.columns)

    this.datasetValue.columns.forEach(item => {
      options.push({text: Object.values(item)[0], value: Object.values(item)[0], selected: true})
      columns.push(Object.values(item)[0])
    })

    const columnController = this.application.getControllerForElementAndIdentifier(this.element, "column")

    columnController.columnsValues = columns
    this.columnSelectTarget.slim.setData(options)
  }
}
