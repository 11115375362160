import { Controller } from "stimulus"
import Sortable from "sortablejs"
import { UrlHelper } from "../helpers/url_helper"

export default class extends Controller {
  static targets = ["inquiry"]

  connect() {
    this.initializeSortable()
  }

  initializeSortable() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.sort.bind(this),
      handle: '.grip', // handle's class
    })
  }

  sort() {
    let ids = []

    this.inquiryTargets.forEach(element => {
      ids.push(element.dataset.dashboardInquiryIdValue)
    })

    fetch(UrlHelper.account_url('/dashboards_inquiries/sort'), {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ids})
    })
  }

  changeWidth(e) {
    const inquiryElement = e.currentTarget.parentNode.parentNode.parentNode

    e.preventDefault()
    inquiryElement.classList.toggle("dashboard__item--wide")

    const width = inquiryElement.classList.contains("dashboard__item--wide") ? "wide" : "narrow"

    if (width === "wide") {
      e.currentTarget.classList.add('fa-compress-arrows-alt')
      e.currentTarget.classList.remove('fa-expand-arrows-alt')
    } else {
      e.currentTarget.classList.remove('fa-compress-arrows-alt')
      e.currentTarget.classList.add('fa-expand-arrows-alt')
    }

    fetch(UrlHelper.account_url(`/dashboards_inquiries/${inquiryElement.dataset.dashboardInquiryIdValue}`), {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ width: width })
    })

    //Redraw chart if container on dashboard is resized
    if (inquiryElement.hasAttribute("data-chart-target")) {
      const chartController = this.application.getControllerForElementAndIdentifier(inquiryElement, "chart")

      chartController.chart.destroy()
      chartController.createChart()
    }
  }
}
