import { Controller } from "stimulus"
import  SlimSelect from 'slim-select'
import "slim-select/src/slim-select/slimselect.scss"

export default class extends Controller {

  initialize() {
    new SlimSelect({
      select: this.element,
      placeholder: this.element.dataset.placeholder,
      showSearch: false
    })
  }
}
