import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "type",
  ]

  setComparisonType(e) {
    this.typeTargets.forEach(target => {
      if(target.dataset.type === e.target.value) {
        target.classList.remove("hidden")
      } else {
        document.getElementById("inquiry_chart").value = ""
        document.getElementsByTagName("canvas")[0].parentNode.classList.add("hidden")
        target.classList.add("hidden")
      }
    })
  }
}
