import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import { FlashHelper } from "../helpers/flash_helper"

export default class extends Controller {

  static values = {
    id: Number
  }

  toggleFavorite(e) {
    e.preventDefault()

    const self = this,
      url = e.currentTarget.dataset.url

    Rails.ajax({
      type: e.currentTarget.dataset.type,
      url: `${url}?id=${self.idValue}`,
      dataType: "script",
      success: function() {
        document.getElementById("addBtn").classList.toggle("hidden")
        document.getElementById("removeBtn").classList.toggle("hidden")
      },
      complete: function(data) {
        FlashHelper.renderAjaxMessage(data)
      }
    })
  }
}
