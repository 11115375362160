export class ChartOptions {
  static pie(data) {
    return {
      type: 'pie', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getPieChartDatasets(data)
      }
    }
  }

  static bubble(data) {
    return {
      type: 'bubble', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getBubbleChartDatasets(data)
      }
    }
  }

  static scatter(data) {
    return {
      type: 'scatter', 
      data: {
        datasets: this._getScatterChartDatasets(data)
      },
      options: {
        scales: {
          xAxes: [{
            type: 'linear',
            position: 'bottom'
          }]
        }
      }
    }
  }

  static bar(data) {
    return {
      type: 'bar', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getSimpleChartDatasets(data)
      }
    }
  }

  static horizontalBar(data) {
    return {
      type: 'horizontalBar', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getSimpleChartDatasets(data)
      }
    }
  }

  static column(data) {
    return {
      type: 'bar', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getSimpleChartDatasets(data)
      },
      options: {
        scales: {
          yAxes: [{
            stacked: true
          }],
          xAxes: [{
            stacked: true
          }]
        }
      }
    }
  }

  static stackedArea(data) {
    return {
      type: 'line', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getSimpleChartDatasets(data)
      },
      options: {
        scales: {
          yAxes: [{
            stacked: true
          }],
          xAxes: [{
            stacked: true
          }]
        }
      }
    }
  }

  static area(data) {
    return {
      type: 'radar', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getSimpleChartDatasets(data)
      }
    }
  }

  static line(data) {
    return {
      type: 'line', 
      data: {
        labels: data.map(object => Object.values(object)[0]),
        datasets: this._getLineChartDatasets(data)
      }
    }
  }

  //private

  static _getSimpleChartDatasets(data) {
    let datasets = []

    Object.entries(data[0]).forEach((property, index) => {
      if (index === 0) return 
      
      datasets.push({
        label: property[0],
        backgroundColor: '#' + Math.random().toString(16).substring(9),
        data: data.map(object => Object.values(object)[index])
      })
    })

    return datasets
  }

  static _getLineChartDatasets(data) {
    let datasets = []
    let color = ""
    Object.entries(data[0]).forEach((property, index) => {
      if (index === 0) return 
      
      color = '#' + Math.random().toString(16).substring(9)
      datasets.push({
        label: property[0],
        backgroundColor: color,
        borderColor: color,
        data: data.map(object => Object.values(object)[index]),
        fill: false
      })
    })

    return datasets
  }

  static _getPieChartDatasets(data) {
    let datasets = []
    let dataColors = []

    data.forEach(() => {
      dataColors.push('#' + Math.random().toString(16).substring(9))
    })
    
    datasets.push({
      backgroundColor: dataColors,
      data: data.map(object => Object.values(object)[1])
    })

    return datasets
  }

  static _getBubbleChartDatasets(data) {
    let datasets = []

    datasets.push({
      label: Object.entries(data[0])[0],
      backgroundColor: '#' + Math.random().toString(16).substring(9),
      data: data.map(object => ({ 
        x: Object.values(object)[0],
        y: Object.values(object)[1],
        r: Object.values(object)[2],
      }))
    })

    return datasets
  }

  static _getScatterChartDatasets(data) {
    let datasets = []

    Object.entries(data[0]).forEach((property, index) => {
      if (index % 2 != 0) return

      datasets.push({
        label: property[0],
        backgroundColor: '#' + Math.random().toString(16).substring(9),
        data: data.map(object => ({ 
          x: Object.values(object)[index],
          y: Object.values(object)[index + 1]
        }))
      })
    })

    return datasets
  }
}