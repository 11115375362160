import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "email", "first_name", "last_name", "password", "role", "locale" ]

  toggleShowEdit(event) {
    const targetName = event.target.dataset.field

    this[targetName + "Targets"].forEach(target => {
      target.classList.toggle("hidden")
    })
  }
}
