export class FlashHelper {
  static render(msg, alertType) {
    if (alertType === "error" || alertType === "notice" || alertType === "alert")
      if (alertType !== "empty") {
        const msgNew = this.replaceCharacters(msg)
        if (alertType.indexOf("keep") !== 0 && msgNew) {
          const messageHTML = this.generateMessageHTML(alertType, msgNew),
            previousFlash = document.getElementById("flash_hook")
          if(previousFlash)
            previousFlash.parentElement.removeChild(previousFlash)
          document.body.insertAdjacentHTML('afterbegin', messageHTML)
        }

        if (!msgNew)
          document.getElementById("flash_hook").replaceWith("<div id='flash_hook'></div>")
      }
  }

  static renderFetchMessage(response) {
    const msg = response.headers.get("X-Message"),
          alertType = response.headers.get("X-Message-Type")
    this.render(msg, alertType)
  }

  static renderAjaxMessage(response) {
    const msg = response.getResponseHeader("X-Message"),
          alertType = response.getResponseHeader("X-Message-Type")
    this.render(msg, alertType)
  }

  static generateMessageHTML(alertType, message) {
    return `
    <div class="fixed flash inset-x-0 mx-auto max-w-3xl" style="top: 10%" data-controller="flash-message" id="flash_hook">
      <div class="flash--${alertType} border-t-4 rounded-b px-4 py-3 shadow-md" role="alert">
        <div class="flex">
          <div class="py-1">
            <svg class="fill-current h-6 w-6 flash-icon--${alertType} mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
          </div>
          <div class="font-bold ml-5 whitespace-pre-line">${message}</div>
          <div class="font-bold text-2xl ml-auto cursor-pointer" data-action="click->flash-message#close">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    `
  }

  static replaceCharacters(message) {
    let validMessage = message

    validMessage = validMessage.replace(/Å¡/g, "š")
    validMessage = validMessage.replace(/Å/g, "Š")
    validMessage = validMessage.replace(/Ä/g, "č")
    validMessage = validMessage.replace(/Ä/g, "Č")
    validMessage = validMessage.replace(/Ä/g, "ć")
    validMessage = validMessage.replace(/Ä/g, "Ć")
    validMessage = validMessage.replace(/Å¾/g, "ž")
    validMessage = validMessage.replace(/Å½/g, "Ž")
    validMessage = validMessage.replace(/Š¾/g, "ž")
    validMessage = validMessage.replace(/Ä/g, "đ")
    validMessage = validMessage.replace(/Ä/g, "Đ")
    validMessage = validMessage.replace(/dÅ¾/g, "dž")
    validMessage = validMessage.replace(/DÅ¾/g, "Dž")

    return validMessage
  }
}
