import { Controller } from "stimulus"
import { ChartOptions } from "../helpers/chart_options"
import Chart from 'chart.js'
import { Guid } from "../helpers/guid"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["chart"]
  static values = {
    queryData: Array,
    type: String,
    inquiryId: Number,
    dashboardInquiryId: Number
  }

  connect() {
    if (!this.element.dataset.loaded) {
      this.subToChannel()
      this.setChartDefaults()
      this.executeQuery()
    }
  }

  queryDataValuesChanged() {
    if (this.hasQueryDataValues) {
      this.createChart()
      this.element.setAttribute("data-loaded", "true")
      this.element.querySelector(".tabulator-loader").classList.add("hidden")
    }
  }

  createChart() {
    this.chart = new Chart(this.chartTarget, ChartOptions[this.typeValue](this.getFilteredTableData()))
    this.chart.options.animation.duration = 0
  }

  setChartDefaults() {
    Chart.defaults.scale.gridLines.display = false
    Chart.defaults.global.legend.display = false
    Chart.defaults.scale.ticks.callback = tick => {
      var characterLimit = 5;
      if ( tick.length >= characterLimit) {
        return tick.slice(0, tick.length).substring(0, characterLimit -1).trim() + '...'
      } 
      return tick
    }

    let loader = document.getElementById("loader").innerHTML
    this.element.querySelector(".tabulator").insertAdjacentHTML("beforeend", loader)
  }

  getFilteredTableData() {
    let data = this.queryDataValues

    const columnController = this.application.getControllerForElementAndIdentifier(this.element, "column")
    let columns = columnController.columnsValues

    return data.map(item => {
      let object = {}
      columns.forEach(column => {
        object[column] = item[column]
      })
      return object
    })
  }

  subToChannel() {
    const uuid = Guid.generate()
    let self = this

    this.subscription = consumer.subscriptions.create(
      { channel: "InquiryChannel", uuid: uuid },
      {
        received(response) {
          self.queryDataValues = response.content.data
        },
        run() {
          this.perform("run", { id: self.inquiryIdValue, editable: false, format: "html" })
        }
      }
    )
  }

  executeQuery() {
    const self = this

    this.element.querySelector(".tabulator-loader").classList.remove("hidden")
    setTimeout(() => self.subscription.run(), 2000)
  }
}