import { Controller } from "stimulus"
import { Guid } from "../helpers/guid"
import consumer from "../channels/consumer"
  
export default class extends Controller {
  static targets = ["label"]
  static values = {
    inquiryId: Number,
    dashboardInquiryId: Number
  }
  
  connect() {
    if (!this.element.dataset.loaded) {
      this.subToChannel()
      this.attachLoader()
      this.executeQuery()
    }
  }
  
  subToChannel() {
    const uuid = Guid.generate()
    let self = this
    
    this.subscription = consumer.subscriptions.create(
      { channel: "InquiryChannel", uuid: uuid },
      {
        received(response) {
          const tableData = response.content.data,
            mainData = Object.values(tableData[0])[0],
            label = Object.keys(tableData[0])[0].toLowerCase()

          self.labelTarget.innerHTML = `
            <div class="mt-5 text-center" data-inquiry-target="label">
              <span class="font-semibold text-6xl">${self.formatIfNumber(mainData)}</span>
              <span class="text-5xl font-light">${label}</span>
            </div>
          `
          self.element.setAttribute("data-loaded", "true")
          self.element.querySelector(".tabulator-loader").classList.add("hidden")
        },
        run() {
          this.perform("run", { id: self.inquiryIdValue, editable: false, format: "html" })
        }
      }
    )
  }

  formatIfNumber(value) {
    const parsed = parseInt(value)
    if (isNaN(parsed)) { return value }
    return parsed.toLocaleString("de-DE")
  }

  attachLoader() {
    let loader = document.getElementById("loader").innerHTML
    this.element.querySelector(".tabulator").insertAdjacentHTML("beforeend", loader)
  }

  executeQuery() {
    const self = this
    
    this.element.querySelector(".tabulator-loader").classList.remove("hidden")
    setTimeout(() => self.subscription.run(), 2000)
  }
}