const Tabulator = require("tabulator-tables")

export class TableRegister {
  constructor() {
    this.bucket = {}
    this.register = (key, container, options) => {
      if (typeof key === "string")
        this.bucket[key] = new Tabulator(container, options)
    }
    this.getInstance = key => {
      let table

      if (typeof key === "string")
        table = this.bucket[key]

      return table
    }
  }
}
