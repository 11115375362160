import { Controller } from "stimulus"
import ace from "brace"
import "brace/theme/sqlserver"
import "brace/theme/solarized_light"
import "brace/mode/sql"
import "brace/mode/yaml"

// Wrapper around acejs code editor. There is a bit complexity here since we
// can't attach brace directly to the rails form helper. We need to attach it to
// div and then sync changes to hidden form element, othervise it will not
// pass-through params.
export default class extends Controller {
  static targets = ["field", "editor", "submit"]

  connect() {
    const mode = this.element.getAttribute("data-mode"),
        theme = this.element.getAttribute("data-theme"),
        showLineNumbers = (/true/i).test(this.element.getAttribute("data-show-line-numbers")),
        editor = ace.edit(this.editorTarget),
        session = editor.getSession()

    editor.setOptions({
      theme: `ace/theme/${theme}`,
      mode: `ace/mode/${mode}`,
      minLines: 13,
      maxLines: 50,
      fontSize: "14px",
      wrap: true,
      useSoftTabs: true,
      tabSize: 2,
      showLineNumbers: showLineNumbers
    })

    this.initiateEditorCommands(editor)
    this.setValue(editor)
    this.hookOnChange(session)
  }

  initiateEditorCommands(editor) {
    editor.commands.addCommand({
      name: 'execute',
      bindKey: { "win": "Shift-Enter", "mac": "Shift-Enter" },
      exec: () => {
        this.submitTarget.click()
      }
    })
  }

  // Set value if present on the field. Usually on form edit.
  setValue(editor) {
    if (this.fieldTarget.value !== "") {
      editor.setValue(this.fieldTarget.value)
      editor.clearSelection()
    }
  }

  // Hide form input, and sync changes between two fields.
  hookOnChange(session) {
    session.on("change", () => {
      this.fieldTarget.value = session.getValue()
    })
  }
}