import { Controller } from "stimulus"

export default class extends Controller {

  exportPDF(e) {
    e.preventDefault()
    
    const showPage = document.getElementById("showPage"),
      inquiryController = this.application.getControllerForElementAndIdentifier(showPage, "inquiry")

    inquiryController.subscription.run("", "pdf")
  }

  exportXLSX(e) {
    e.preventDefault()

    const showPage = document.getElementById("showPage"),
      inquiryController = this.application.getControllerForElementAndIdentifier(showPage, "inquiry")

    inquiryController.subscription.run("", "xlsx")
  }
}
