import { Controller } from "stimulus"
import Tippy from "tippy.js"
import "tippy.js/themes/light.css"

export default class extends Controller {
  connect() {
    const template = document.getElementById(this.element.dataset.templateId)
    const container = document.createElement('div')
    if (template.nodeName === "TEMPLATE")
      container.appendChild(document.importNode(template.content, true))
    else
      container.innerHTML = template.innerHTML

    const self = this

    Tippy(self.element, {
      maxWidth: self.maxWidth,
      theme: "light",
      content: container.innerHTML,
      trigger: self.trigger,
      interactive: true,
      arrow: true,
      flip: true,
      flipOnUpdate: true,
      boundary: "viewport",
      placement: "bottom-start"
    })
  }

  get trigger() {
    return this.element.dataset.hover ? "mouseenter" : "click"
  }

  get maxWidth() {
    return this.element.dataset.width || "600px"
  }
}
