import { Controller } from "stimulus"
import Flatpickr from 'stimulus-flatpickr'
import "flatpickr/dist/themes/airbnb.css"

export default class extends Flatpickr {

  connect() {
    this.config = {
      locale: this.locale,
      allowInput: true,
      altInput: true,
      enableTime: this.enableTime,
      showMonths: 1
    }

    super.connect()
  }

  get enableTime() {
    return this.element.dataset.enableTime || false
  }
}
