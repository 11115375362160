import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["form", "queryInput", "results"]

  initialize() {
    // Send request when user stops typing
    this.attemptSearch = debounce(this.attemptSearch, 400)
  }

  // Actions

  focus() {
    const add_list = ['italic', 'font-medium', 'text-center', 'pt-16', 'text-gray-300'];
    const remove_list = ['hidden', 'text-gray-800'];

    this.resultsTarget.classList.remove(...remove_list);
    this.resultsTarget.classList.add(...add_list);
    this.resultsTarget.innerHTML = this.resultsTarget.dataset.confirm;
  }

  attemptSearch() {
    if (this.hasMoreThanOneChar) {
      this.performSearch()
    } else {
      this.reset()
    }
  }

  reset() {
    this.resetResults()
  }

  resetOnEscape(event) {
    if (event.keyCode == 27) {
      event.stopPropagation()
      this.reset()
    }
  }

  dissapear(event) {
    if (this.queryInputTarget.contains(event.target) == false) {
      this.resultsTarget.innerHTML = ""
      this.resultsTarget.classList.add("hidden")
    }
  }

  // Private

  performSearch() {
    const url = `${this.baseUrl}?q=${this.serializedQuery}`

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const remove_list = ['italic', 'font-medium', 'text-center', 'pt-16'];
        const add_list = ['text-gray-800'];
        this.resultsTarget.classList.remove(...remove_list);
        this.resultsTarget.classList.add(...add_list);
        this.resultsTarget.innerHTML = data.results;
      })
    .catch(error => console.log("Ooops..."))
  }

  resetResults() {
    this.resultsTarget.innerHTML = ""
  }

  get hasMoreThanOneChar() {
    return this.queryInputTarget.value.length > 1
  }

  get baseUrl() {
    return this.formTarget.action
  }

  get serializedQuery() {
    return encodeURIComponent(this.queryInputTarget.value)
  }
}