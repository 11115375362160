import { Controller } from "stimulus"

export default class extends Controller  {
  static values = {
    columns: Array,
    firstRun: Boolean
  }
  
  toggleColumns(e) {
    let newColumns = []
    const options = [...e.target.options]

    newColumns = options.filter(element => element.selected).map(option => option.label)

    this.columnsValues = newColumns

    this.toggleColumnVisibility()
  }

  toggleColumnVisibility(dashboardInquiryId = null) {
    let tableId = ""

    if (dashboardInquiryId == null) {
      tableId = "showTable"
    } else {
      tableId = `${dashboardInquiryId}_table`
    }

    const table = TableRegister.getInstance(tableId)
      
    if (table != undefined) {
      table.getColumns().forEach(column => {
        column.hide()
      })
    }

    this.columnsValues.forEach(column => {
      table.showColumn(column)
    })
  }
}