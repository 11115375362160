import { Controller } from "stimulus"
import Tippy from "tippy.js"

export default class extends Controller {
  connect() {
    const element = this.element
    Tippy(element, {
      multiple: true,
      content: element.dataset.tip,
      theme: "tooltip"
    })
  }
}
