import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "adapter", "url" ]

  toggleShowEdit(event) {
    const targetName = event.target.dataset.field

    this[targetName + "Targets"].forEach(target => {
      target.classList.toggle("hidden")
    })
  }
}